// password visibility
$(function () {
    $('.password-group').find('.password-box').each(function (index, input) {
        var $input = $(input);
        $input.parent().find('.password-visibility').click(function () {
            var change = "";
            if ($(this).find('i').hasClass('fa-eye')) {
                $(this).find('i').removeClass('fa-eye')
                $(this).find('i').addClass('fa-eye-slash')
                change = "text";
            } else {
                $(this).find('i').removeClass('fa-eye-slash')
                $(this).find('i').addClass('fa-eye')
                change = "password";
            }
            var rep = $("<input type='" + change + "' />")
                .attr('id', $input.attr('id'))
                .attr('name', $input.attr('name'))
                .attr('class', $input.attr('class'))
                .val($input.val())
                .insertBefore($input);
            $input.remove();
            $input = rep;
        }).insertAfter($input);
    });
})

// href to bootstrap tab on other page
$(function(){
    var hash = window.location.hash;
    hash && $('ul.nav a[href="' + hash + '"]').tab('show');
  
    $('.nav-pills a').click(function (e) {
      $(this).tab('show');
      var scrollmem = $('body').scrollTop() || $('html').scrollTop();
      window.location.hash = this.hash;
      $('html,body').scrollTop(scrollmem);
    });  
  });

  $(document).ready(function() {
    $("body").tooltip({ 
        selector: '[data-toggle=tooltip]',
        trigger: 'hover'
     });

    $("#mobileFilters").click(function(){
        if($("#collapseFilters").hasClass('show')) {
            $("body")[0].style.overflow = "auto"; 
        } else {
            $("body")[0].style.overflow = "hidden"; 
        }
    });

    $('.mobile-apply-filters button, #mobileCloseSearch').click(function(){
        $("body")[0].style.overflow = "auto"; 
    });
});

jQuery(window).load(function () {
    $('body').fadeIn();
});

// scroll in auction
$(document).scroll(function () {
    //Show element after user scrolls 800px
    var y = $(this).scrollTop();
    if (y > 490) {
        $('.thumbnail-image').addClass('active');
    } else {
        $('.thumbnail-image').removeClass('active');
    }
});

// arrows animation
const faqElements = [...document.querySelectorAll('.activate-rotation')];
faqElements.map(function (item) {
    item.addEventListener('click', function () {
        this.querySelector('.rotate').classList.toggle('down')
    })
});

// fotorama
jQuery(function() {
    // Initialize fotoramas and register show event
    $('.fotorama')
        .on('fotorama:fullscreenenter', function (e, fotorama) {
            // Make a related link as active
            let fotoramaId = $(this).attr('id'),
                currentId = fotorama.activeFrame.id,
                fotoramaLinks = $('[data-fotorama="#' + 'main' + '"] a');
            
            fotoramaLinks
                .removeClass('active')
                .filter('[href="#' + currentId +'"]')
                .addClass('active');
        })
    
    // Click on the links
    $(document).on('click', '.fotorama-links a', function (e) {
        e.preventDefault();
        
        const $this = $(this),
            frameId = $this.attr('href').replace(/^#/, ''),
            fotoramaSelector = $this.parents('.fotorama-links').data('fotorama'),
            fotoramaAPI = $(fotoramaSelector).data('fotorama'),
            fotoramaLinks = $('[data-fotorama="#main"] a');
        
        if (fotoramaAPI) {
            fotoramaAPI.show(frameId);
            
            fotoramaLinks
                .removeClass('active')
                .filter('[href="#' + frameId +'"]')
                .addClass('active');
        }
    });
})

// bootstrap validation
let validate = function() {
    window.addEventListener('load', function() {
      // Fetch all the forms we want to apply custom Bootstrap validation styles to
      var forms = document.getElementsByClassName('needs-validation');
      // Loop over them and prevent submission
      var validation = Array.prototype.filter.call(forms, function(form) {
        form.addEventListener('submit', function(event) {
          if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
          }
          form.classList.add('was-validated');
        }, false);
      });
    }, false);
  }();